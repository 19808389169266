@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;1,900&display=swap");

body {
  font-family: "Montserrat", sans-serif !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

.gradient-text {
  background-image: linear-gradient(to bottom right, #e94242, rgb(150, 185, 255));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.gradient-text2 {
  background-image: linear-gradient(to bottom right, #2a20ec, rgb(50, 183, 255));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.login-message-container {
  animation: moveLeftToRight 5s linear infinite;
}

.fixednav{
  background-image: url("https://d2kh7o38xye1vj.cloudfront.net/wp-content/uploads/2023/11/stripBG.png");
}

.scroll-kit::-webkit-scrollbar {
  width: 10px; 
}

.scroll-kit::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners of the scrollbar handle */
}

.scroll-kit::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}


.signupp{
  position: relative;
  z-index: 1100;
}

@keyframes moveLeftToRight {
  0% {
    transform: translateX(-20%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-20%);
  }
}
.borderyy{
  border-left: 1px solid black;
  border-top:1px solid black ;
  border-bottom:1px solid black ;

}

.abovecontain{
  position: relative;
  z-index: 1;
}

  .abovecontain2{
  position: absolute;
  z-index: 3000;
}

.rc-pagination-options-quick-jumper {
  display: inline-block;
  height: 34px !important;
  line-height: 28px !important;
  vertical-align: top;
}



.navbar{
  padding : 0px
}

a{
  text-decoration: none;
}

.minimal-space{
  height : 3px;
  width : 100%;
}

.text-spacing {
  white-space: pre-wrap;
}

.otp-input {
  display: flex;
  justify-content: center;
}

.otp-input input {
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  padding: 0.5rem;
  font-size: 1.2rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .otp-input input {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
    margin: 0.2rem;
  }
}






