  
  .trigger {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .trigger:hover {
    color: #2d69a4;
  }
  
  .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-layout {
    background-color: rgb(13, 127, 188);

    min-height: 100vh;
  }
  .ant-layout-sider {
    background-color: rgb(240, 29, 29);
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
  }
  .ant-layout-sider-collapsed {
    background-color: rgb(240, 29, 29);

    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
  }
  .sm-logo {
    display: none;
  }
  
  .ant-layout-sider-collapsed .sm-logo {
    display: block;
  }
  .ant-layout-sider-collapsed .lg-logo {
    display: none;
  }
  .lg-logo{
    color: blue;
  }
  .logo {
    background-color: #ffffff;
    margin: 0;
    height: 64px;
  }
  
  .ant-menu-title-content {
    font-size: 16px;
  }
  .ant-layout-header h5 {
    line-height: 20px;
    font-size: 14px;
  }
  .ant-layout-header p {
    line-height: 20px;
    font-size: 13px;
  }
  .ant-layout-header div .badge {
    top: 16px;
    right: -6px;
  }
  .error {
    color: red;
    font-size: 12px;
  }
 
  
  .green {
    color: var(--color-47ad24);
    font-size: 14px;
    font-family: Roboto !important;
  }
  .red {
    color: var(--color-212529);
    font-size: 14px;
    font-family: Roboto !important;
  }
  .quill {
    background-color: white;
  }
  
  .form-control {
    color: rgb(255, 255, 255);
    box-shadow: none;
    border-color: var(--color-212529);
  }
  .title {
    font: 28px;
    font-family: Roboto !important;
    color: var(--color-212529);
  }
  
  .sub-title {
    font: 24px;
    font-family: Roboto !important;
    color: var(--color-212529);
  }
  .desc {
    font: 14px;
    font-family: Roboto !important;
    color: var(--color-828599);
  }