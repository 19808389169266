 

  .popup-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background-color: rgba(199, 199, 199, 0.368); /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
  }
  
  .result-popup {
    background-color: white;
    max-width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* drop shadow */
    text-align: center;
  }
  