.tab-container {
  display: flex;
  justify-content: space-evenly;
}

.tab {
  cursor: pointer;
  padding-top: 20px;
  font-size: 15px;
  font-weight: bold;
  /* color:#5648FC; */
  font-weight: 900;
  border-bottom: 2px solid;
}

.tab-content {
  display: none;
  width: 100%;
  height: 100%;
}

.tab-content.active {
  display: block;
}

#test_cards_content {
  height: 100vh;
  width: 80%;
  margin: auto;
  margin-top: 20px;
  /* background-color: #5648FC; */
}
.fix-color {
  background-color: #F1F1F1;
  border-color: #F1F1F1;
}
