.custom-tiered-menu {
    font-family: Arial, sans-serif;
}

.p-button-icon {
    margin-left: 6px;
}

.p-submenu-list {
    max-height: 300px;
    overflow-y: auto;
}

.submenu-item {
    text-align: center;
    padding: 0.5rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid #414141;
}

.submenu-item h3 {
    margin: 0.5rem 0;
    font-size: 1rem;
    font-weight: bold;
}
.p-submenu-list>.p-menuitem:last-child .submenu-item {
    border-bottom: none;
}

.exam-logo {
    width: 40px;
    height: 40px;
    margin: 0.5rem 0;
}

.submenu-links {
    margin: 0.5rem 0;
}

.apply-link,
.official-link {
    display: inline-block;
    margin: 0.25rem;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
    font-size: 0.85rem;
}

.apply-link {
    background-color: #007bff;
}

.official-link {
    background-color: #28a745;
}

.official-link:hover {
    background-color: #1e7e34;
}

.animated-bell {
    position: relative;
    animation: bell-shake 2s infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.animated-bell .pi-bell {
    font-size: 1.5rem;
    animation: bell-swing 0.8s ease-in-out infinite alternate;
}

/* Animation for the Bell Icon */
@keyframes bell-swing {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(5deg);
    }
}

@keyframes bell-shake {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

/* Mobile Devices */
@media (max-width: 767px) {
    .submenu-item {
        padding: 1rem;
    }
    .exam-logo {
        width: 48px;
        height: 36px;
    }
    .calendar-logo {
        width: 48px;
        height: 48px;
    }
    .apply-link,
    .official-link {
        font-size: 0.875rem;
    }
}

.p-tieredmenu {
    border: none;
}

.p-tieredmenu .p-menuitem {
    margin: 0.25rem 0;
}

.p-tieredmenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
}

.p-submenu-list {
    max-height: 300px;
    overflow-y: auto;
    border-radius: 10px;
}

.p-tieredmenu .p-menuitem-link:hover {
    background-color: rgb(243 244 246);
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: rgb(17 24 39);
    font-weight: 500;
}

@media screen and (max-width: 1024px) {
    .p-tieredmenu .p-menuitem-active>.p-submenu-list {
        left: 0;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
    }

    .p-tieredmenu .p-submenu-list {
        width: 100%;
        position: relative;
    }

    .p-tieredmenu .p-menuitem {
        margin: 0;
    }

    .p-tieredmenu .p-menuitem-link {
        border-radius: 0;
    }
}

.menu-root {
    background: white;
    min-width: 320px;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border: 1px solid rgb(229 231 235);
    padding: 0.5rem;
}

/* .menu-submenu {
    background: white;
    min-width: 320px;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-top: 1px solid rgb(229 231 235);
} */