.forgot-body{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.forgot-form{
    border: 0.5px solid rgb(192, 191, 191);
    border-radius: 10px;
    padding: 0.5rem 2rem 1rem 2rem;
    max-height: 90vh;
    width: 20vw;
    position: relative;
    bottom: 10vh;
    background-color: white;

    @media (max-width: 800px){
        width: 40vw;
    }

    @media(max-width: 550px) {
        width: 60%;
    }
}

.forgot-form h2{
    color: rgb(32, 32, 32);
    font-size: 25px;
    font-weight: 400;

    @media (max-width: 800px) {
        font-size: 22px;
    }
    
    @media(max-width: 550px) {
        font-size: 20px;
    }
}

.forgot-description-p{
    border: 1px solid #e6db55;
    padding: 0.85rem;
    background-color: #0e2aa82a;
    color: gray;
    width: 90%;
    border-radius: 10px;
    text-align: justify;
    font-size: 1rem;

    @media (max-width: 800px) {
        font-size: 0.9rem;
    }
    
    @media(max-width: 550px) {
        font-size: 0.85rem;
    }
}

.forgot-description-input{
    color: rgb(70, 69, 69);
    border: 0.5px solid rgb(192, 191, 191);
    border-radius: 5px;
    width: 92%;
    font-size: 1rem;
    padding: 0.6rem;

    @media (max-width: 800px) {
        font-size: 0.9rem;
        /* width: 20vw; */
    }
    
    @media(max-width: 550px) {
        font-size: 0.85rem;
        /* width: 36%; */
    }
}

.forgot-btn{
    background-color: #123456;
    color: #fff;
    padding: 0.6rem;
    border-radius: 10px;
    border: none;
    font-size: 1rem;
    margin-bottom: 1rem;
    margin-top: 0.8rem;
}

.forgot-btn:hover {
    background-color: #121212;
    cursor: pointer;
}