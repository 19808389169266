.custom-shape-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 248px;
    position: relative;
    top: -1px;
    transform: rotateY(180deg);
}

.custom-shape-divider .shape-fill {
    fill: #FFFFFF;
}

@media (max-width: 767px) {
    .custom-shape-divider svg {
        width: calc(100% + 1.3px);
        height: 85px;
    }
}